/* formulation-table */
.formulation-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 12px;
    text-align: left;
    table-layout: auto;
    /* Allows columns to adjust based on content */
}

.formulation-table th,
.formulation-table td {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: middle;
    white-space: nowrap;
    /* Prevent content wrapping */
}

.formulation-table th {
    text-align: right;
    background-color: #847575;
    font-weight: bold;
}

.formulation-table td {
    text-align: left;
}

/* Dynamic table inside cells */
.formulation-table table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    margin: 0;
}

.formulation-table table th,
.formulation-table table td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: center;
    white-space: nowrap;
}

.input-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.input-wrapper input {
    width: 60px;
    text-align: right;
    border: 1px solid #ddd;
    padding: 2px;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.input-wrapper .unit {
    font-weight: bold;
    color: #555;
    white-space: nowrap;
}

.tank-table {
    width: 300px;
    border-collapse: collapse;
    font-size: 12px;
    table-layout: fixed;
    /* Ensure cells are equally spaced */
}

.tank-table thead th {
    background-color: #453030;
    color: #fff;
    text-align: center;
    padding: 4px;
    border: 1px solid #ccc;
    white-space: nowrap;
    /* Prevent text from wrapping in headers */
}

.tank-table tbody td {
    text-align: center;
    padding: 2px 4px;
    border: 1px solid #ccc;
    word-break: break-word;
    /* Allow text to wrap if necessary */
    vertical-align: middle;
    width: 50px;
    /* Reduce width of cells explicitly */
    max-width: 50px;
    /* Ensure a maximum width for compactness */
}

.tank-table input {
    width: calc(100% - 4px);
    /* Ensure input doesn't overflow the cell */
    border: none;
    outline: none;
    padding: 2px;
    text-align: center;
    font-size: 12px;
    box-sizing: border-box;
    background-color: transparent;
}

.tank-table input:focus {
    border: 1px solid #000;
    background-color: #fff;
}

.formulation-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.3rem;
}

.formulation-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #222;
}

.formulation-detail-link {
    font-size: 0.9rem;
    color: #0073e6;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.formulation-detail-link:hover {
    text-decoration: underline;
    color: #004a99;
}