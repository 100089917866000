/* General Content Wrapper */
.content-wrapper {
    padding: 20px;
    overflow: hidden;
}

/* Tables Wrapper for Horizontal Scroll */
.tables-wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    /* Adjusted gap to fit the card padding */
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0;
    /* Padding for better spacing inside the card */
    scrollbar-color: #c1c1c1 transparent;
    scrollbar-width: thin;
}

/* Pretty Scrollbar for Webkit Browsers */
.tables-wrapper::-webkit-scrollbar {
    height: 8px;
}

.tables-wrapper::-webkit-scrollbar-track {
    background: #f4f4f4;
    border-radius: 4px;
}

.tables-wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    border: 2px solid #f4f4f4;
}

/* Error Modal */
.error-modal {
    background-color: #f8d7da;
    color: #842029;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.formulation-header {
    display: flex;
    justify-content: flex-start;
    /* Aligns the button to the left */
    margin-bottom: 1px;
    /* Adds spacing between the button and the tables */
}

.select-formulation-button {
    padding: 8px 12px;
    /* Smaller padding to fit well with the card style */
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    background-color: #007bff99;
    /* Primary button color */
    border: none;
    border-radius: 6px;
    /* Rounded corners to match the card's style */
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.2s;
    width: fit-content;
}

.select-formulation-button:hover {
    background-color: #0057b399;
    /* Darker blue on hover */
    transform: translateY(-1px);
    /* Subtle lift effect */
}

.select-formulation-button:active {
    background-color: #00408591;
    /* Darkest blue on active state */
    transform: translateY(0);
    /* Reset lift */
}

/* Save Button */
.save-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}

/* Modal Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.popup-content h2 {
    margin-bottom: 20px;
    font-size: 18px;
}

.popup-content pre {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    text-align: left;
    overflow: auto;
    max-height: 300px;
}

.close-button {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #c82333;
}

.excel-preview table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.excel-preview th,
.excel-preview td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.excel-preview th {
    background-color: #f2f2f2;
}

/* Main container styling */
.work-order-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
    width: 100%;
}

/* Row layout for each field */
.work-order-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.three-column-layout {
    display: flex;
    gap: 20px;
    padding: 20px;
    overflow: auto;
    height: 100vh;
    box-sizing: border-box;
}

/* Individual Column Styling */
.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Column Sizing */
.column-1 {
    min-width: 400px;
}

.column-2 {
    min-width: 450px;
}

.column-3 {
    min-width: 350px;
}


/* Instruction Textarea */
.instruction-textarea {
    width: 80%;
    min-height: 80px;
    max-height: 180px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    background: #fff;
    overflow: hidden;
    resize: none;
    line-height: 1.5;
}

.section-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.2rem;
}

.column h4 {
    margin-top: 1.2rem;
    margin-bottom: 0.4rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.2rem;
    color: #333;
}

.form-input {
    height: 22px;
    width: 500px;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #ccc;
    border-radius: 6px;
    outline: none;
    transition: border-color 0.2s;
}

.form-input:focus {
    border-color: #007bff;
}

.input-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 32px;
    /* padding between inputs and tables */
}