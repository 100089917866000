.mixing-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    table-layout: fixed;
}

.mixing-table thead th {
    background-color: #453030;
    color: #fff;
    text-align: center;
    padding: 4px;
    border: 1px solid #ccc;
}

.mixing-table tbody td {
    text-align: center;
    padding: 2px 4px;
    border: 1px solid #ccc;
    word-break: break-word;
    vertical-align: middle;
}

.mixing-table input {
    width: 100%;
    border: none;
    outline: none;
    padding: 2px;
    text-align: center;
    font-size: 12px;
    box-sizing: border-box;
    background-color: transparent;
}

.mixing-table input:focus {
    border: 1px solid #000;
    background-color: #fff;
}

.row th:first-child {
    text-align: right;
    padding: 6px;
}

.row th:last-child {
    text-align: left;
    padding: 6px;
}

.row td:first-child {
    text-align: right;
    padding: 6px;
}

.row td:last-child {
    text-align: left;
    padding: 6px;
}

.section-header td {
    font-weight: bold;
    background-color: #45303091;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 4px;
}

/* Editable cells: Light blue background */
.editable-cell {
    background-color: #e0f7fa;
}

/* Calculated cells: Light green background, right-aligned */
.calculated-cell {
    background-color: #f1f8e9;
    text-align: right;
}

/* Disabled cells: Light gray background */
.disabled-cell {
    background-color: #e0e0e0;
    text-align: center;
    /* Center the placeholder */
    /* Optionally, make the placeholder italic or styled differently */
}